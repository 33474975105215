import {
	Checkbox,
	FormControl,
	FormControlLabel,
	FormGroup,
	FormLabel,
	IconButton,
	Radio,
	RadioGroup,
} from "@mui/material";
import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import CancelButton from "../CancelButton/CancelButton";
import AddIcon from "@material-ui/icons/Add";
import Note from "@material-ui/icons/Note";
import ConfirmationButton from "../ConfirmationButton/ConfirmationButton";
import "./InspectionTypeContainer.css";
import useGetAllInspectionTypes from "../../../hooks/useGetAllInspectionTypes";
import useGetAllInspectionReasons from "../../../hooks/useGetAllInspectionReasons";
import AddInspectionTypeModal from "../AddInspectionTypeModal/AddInspectionTypeModal";
import useGetFarmById from "../../../hooks/useGetFarmById";
import LoadingScreen from "../../LoadingScreen/LoadingScreen";
import useGetAllInspectionReportsByFarmId from "../../../hooks/useGetAllInspectionReportsByFarmId";
import { formatDate } from "../../helpers/formatDateHelper";
import inspectionReportService from "../../../services/inspectionReportService";
import companyService from "../../../services/companyService";
import { useSelector } from "react-redux";

const InspectionTypeContainer = () => {
	const [reasonSelected, setReasonSelected] = useState("");
	const [addInspectionTypeModalIsOpen, setAddInspectionTypeModalIsOpen] =
		useState(false);
	const [showAllInspectionReports, setShowAllInspectionReports] =
		useState(false);
	const { inspectionTypes } = useGetAllInspectionTypes();
	const { inspectionReasons } = useGetAllInspectionReasons();
	const location = useLocation();
	const navigate = useNavigate();
	const user = useSelector((state) => state.user);

	const errors = {};
	const selectedFarm = location.state.farm;
	const identity = selectedFarm.identity.split("-");
	if (identity[0] === "Bú") {
		selectedFarm.identity = "Bu-" + identity[1];
	}
	const { inspectionReports } = useGetAllInspectionReportsByFarmId(
		selectedFarm.identity
	);
	const { farm, isLoading } = useGetFarmById(selectedFarm.identity);
	farm.name = farm.newIndividual ? selectedFarm.name : farm.name;
	console.log(selectedFarm);
	const handleInspectionTypeSelection = (e) => {
		var selectedInspectionType = inspectionTypes.find(
			(inspectionType) =>
				inspectionType.id.toString() === e.target.value.toString()
		);
		if (selectedInspectionType) {
			selectedInspectionType.clicked = e.target.checked;
			if (selectedInspectionType.clicked) {
				farm.inspectionTypeInInspection.push(parseInt(e.target.value));
			} else {
				const index = farm.inspectionTypeInInspection.indexOf(
					parseInt(e.target.value)
				);
				if (index > -1) {
					farm.inspectionTypeInInspection.splice(index, 1);
				}
			}
		}
	};

	const handleValidateLinkButton = (e) => {
		if (farm.inspectionTypeInInspection.length < 1) {
			errors.inspectionType = "Velja þarf dýrategund";
		} else {
			delete errors.inspectionType;
		}
		if (!reasonSelected) {
			errors.reasonId = "Velja þarf eftirlitstegund";
		} else {
			delete errors.reasonId;
		}
		if (Object.keys(errors).length > 0) {
			e.preventDefault();
		}
	};
	const handleBack = () => {
		let path = "/company";
		navigate(path);
	};
	var inspectionTypesToShow =
		!isLoading &&
		inspectionTypes.filter((inspectionType) =>
			farm.inspectionTypeIds.includes(inspectionType.id)
		);
	inspectionTypesToShow &&
		inspectionTypesToShow.forEach((type) => (type.isOnFarm = true));
	const handleOpenJaIs = () => {
		let nameSplit = farm.companyName.split(" ");
		let searchString = "";
		nameSplit.forEach((element) => {
			searchString += `${element}%20`;
		});
		window.open(`https://ja.is/?q=${searchString}`, "_blank");
	};
	const handleReasonSelection = (e) => {
		setReasonSelected(e.target.value);
	};
	const handleAddInspectionType = () => {
		setAddInspectionTypeModalIsOpen(true);
	};
	const handleSubmitInspectionTypes = () => {
		setAddInspectionTypeModalIsOpen(false);
	};
	const handleCancelAddInspectionType = () => {
		setAddInspectionTypeModalIsOpen(false);
	};
	const handleShowAllInspectionReports = () => {
		setShowAllInspectionReports(true);
	};
	const handleGetReport = (report) => {
		if (report.isIsleyfurReport) {
			companyService
				.getIsleyfurpdfreport(report.id, user.accessToken.rawData)
				.then((response) => {
					if (!response.ok) {
						throw new Error("Failed to fetch PDF");
					}
					return response.blob();
				})
				.then((blob) => {
					var fileURL = URL.createObjectURL(blob);
					window.open(fileURL);
				});
		} else {
			inspectionReportService
				.getInspectionReportById(report.inspectionId)
				.then((response) => {
					if (!response.ok) {
						throw new Error("Failed to fetch PDF");
					}
					return response.blob();
				})
				.then((blob) => {
					var fileURL = URL.createObjectURL(blob);
					window.open(fileURL);
				});
		}
	};
	farm.name = selectedFarm.newIndividual ? selectedFarm.name : farm.name;
	console.log(farm);

	const inspectionReportsToShow =
		inspectionReports && !showAllInspectionReports
			? inspectionReports.slice(0, 3)
			: inspectionReports;
	return (
		<>
			{" "}
			{isLoading ? (
				<LoadingScreen />
			) : (
				<div className="inspection-type-container">
					<div className="inspection-type-container-header">
						<div>
							Eftirlit á <b>{farm.name}</b>
						</div>
						<div>
							Umráðamaður <b>{farm.companyName}</b>
						</div>
						<div
							className="inspection-type-container-link"
							onClick={handleOpenJaIs}
						>
							Leita að Umráðamanni á Já.is
						</div>
					</div>
					<div className="inspection-type-container-header-lower">
						<div>
							{errors.inspectionType
								? errors.inspectionType
								: "Dýrategundir í eftirliti"}
						</div>
						<IconButton
							aria-label="delete"
							size="small"
							onClick={() => handleAddInspectionType()}
						>
							<AddIcon style={{ color: "green" }} fontSize="small" />
						</IconButton>{" "}
					</div>
					<div className="inspection-type-selection">
						<FormGroup>
							{inspectionTypesToShow.map((inspectionType) => (
								<FormControlLabel
									control={<Checkbox onClick={handleInspectionTypeSelection} />}
									name={inspectionType.isName}
									label={<>{inspectionType.isName}</>}
									key={inspectionType.id}
									value={inspectionType.id}
								/>
							))}
						</FormGroup>
					</div>
					<div className="inspection-type-container-header">
						{errors.reasonId}
					</div>
					<div className="inspection-reason-selection">
						<FormControl fullWidth>
							<FormLabel className="inspection-reason-selection-header">
								<b style={{ color: "black" }}>Eftirlitstegund</b>
							</FormLabel>
							<RadioGroup
								name="id"
								onChange={handleReasonSelection}
								value={reasonSelected}
							>
								{" "}
								{inspectionReasons.map((reason) => (
									<FormControlLabel
										key={reason.id}
										value={reason.id.toString()}
										control={<Radio />}
										label={reason.isName}
									/>
								))}
							</RadioGroup>
						</FormControl>
					</div>
					{farm.farmProductionTypes && (
						<div>
							<b>Bústofnsgögn</b>
							{farm.farmProductionTypes.map((fpt) => (
								<div className="inspection-type-container-animal-data">
									<b>{fpt.farmProductionTypeName} fjöldi:</b>{" "}
									<div className="inspection-type-container-animal-data-count">
										{fpt.liveAnimals}
									</div>
								</div>
							))}
						</div>
					)}
					<div>
						<b>Skýrslur</b>
						<div
							className="reports-icons-list-container"
							onClick={handleShowAllInspectionReports}
						>
							Sjá allt({inspectionReports.length})
						</div>
						<div className="reports-icons-list">
							{inspectionReportsToShow.map((inspectionReport) => (
								<div
									key={inspectionReport.id}
									onClick={() => handleGetReport(inspectionReport)}
								>
									<Note fontSize="large" color="primary" />
									<div id="lol">
										{inspectionReport.isIsleyfurReport && "(Í)"}Skýrsla{" "}
										{formatDate(inspectionReport.inspectionPerformed)}
									</div>
								</div>
							))}
						</div>
					</div>
					<div className="inspection-type-container-button-area">
						<div>
							<CancelButton handleCancel={handleBack} title="Til baka" />
						</div>
						<div>
							<Link
								onClick={handleValidateLinkButton}
								to={
									reasonSelected === "3" ? "/followupinspection" : "/inspection"
								}
								state={{
									inspectionTypes: inspectionTypes,
									inspectionTypeInInspection: farm.inspectionTypeInInspection,
									inspectionReasonId: reasonSelected,
									farm: farm,
								}}
							>
								<ConfirmationButton title="Hefja eftirlit" />
							</Link>
						</div>
					</div>
					<AddInspectionTypeModal
						open={addInspectionTypeModalIsOpen}
						inspectionTypes={inspectionTypes}
						farm={farm}
						handleSubmitInspectionTypes={handleSubmitInspectionTypes}
						handleClose={handleCancelAddInspectionType}
						inspectionTypesToShow={inspectionTypesToShow}
					/>
				</div>
			)}
		</>
	);
};

export default InspectionTypeContainer;
